import * as React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import ScrollingTextBanner from "../components/scrollingTextBanner"
import { graphql } from "gatsby"
import OrderedList from "../components/orderedListNews"
import NextPage from "../components/nextPage"
import Spacer from "../components/spacer"

class NewsPage extends React.Component {
	render() {
		const news = this.props.data.datoCmsNews.newsArticles.map((item) => ({ node: item }))
		const bannerImage = this.props.data.datoCmsNews.image?.gatsbyImageData
		const nextPage = this.props.data.datoCmsNews
		return (
			<Layout className='pth'>
				<SEO title="News on Siera Group" />
				<Header page='News' />
				<ScrollingTextBanner title="News" className='h-a' image={bannerImage} textClassName="" />
				<OrderedList target='_blank' secondClassName='text-initial' items={news} />
				<Spacer />
				<NextPage title={nextPage.nexttitle} link={nextPage.nextlink} image={nextPage.nextimage} />
			</Layout>
		)
	}
}

export default NewsPage

export const query = graphql`
	query News {
		datoCmsNews {
			image {
				gatsbyImageData
			}
			nexttitle
	        nextlink
	        nextimage {
	          gatsbyImageData
	        }
			newsArticles{
					year
					location:publication
					info
					id
					title:date
					link
					featureImage:image {
						gatsbyImageData
					}
			}
		}
	
	}
`
